import { useState, useEffect } from 'react';
import { useParams } from 'next/navigation';
import LoginAvatar from './LoginAvatar';
import { useRouter } from 'next/navigation';
import { useSelector } from 'react-redux';
import { popState } from '@/slices/popSlice';
import authClient from '@/common/apiClient/authClient';
import useCountDown from '@/hook/useCountDown';

// 信箱登入 流程
const EmailLogin = ({ loginStep, setLoginStep, dispatch, setLoginModal, t, user, userLogin }) => {
    const router = useRouter();
    const params = useParams();
    const [{ second, canRefresh }, countDown] = useCountDown(); // 倒數一分鐘
    const { showLogin } = useSelector(popState);
    const { status } = params;

    const [email, setEmail] = useState(null);
    const [name, setName] = useState('');
    const [canSubmit, setCanSubmit] = useState(true);
    const [canSubmitName, setCanSubmitName] = useState(true);
    const [image, setImage] = useState(false);

    // 送出email
    const sendEmailLogin = async () => {
        if (!checkMail(email)) {
            setCanSubmit(false);
            return;
        }

        const originalUrl = window?.location.href;

        try {
            const result = await authClient.emailSignin(email, originalUrl);
            if (result) {
                // 跳檢查信箱步驟
                setLoginStep(3);
            }
        } catch (e) {
            console.error(e);
        }
    };

    // 變更暱稱
    const handleName = async () => {
        if (checkName() || name === '' || name.trim() === '') {
            setCanSubmitName(false);
            return;
        }
        try {
            const data = await authClient.updateNickname(name);
            if (data) {
                dispatch(userLogin({ ...user, nickname: name }));
                setLoginStep(7);
            }
        } catch (e) {
            console.error(e);
        }
    };

    // 檢查信箱
    const checkMail = () => {
        const pattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g;
        return pattern.test(email);
    };

    // 檢查暱稱
    const checkName = () => {
        const pattern = new RegExp(
            "[`ˊˇˋ˙~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]"
        );
        return pattern.test(name);
    };

    // 略過
    const skip = (step) => {
        if (user?.uid) dispatch(setLoginModal(false));
        if (step !== 'first') router.push('/');
        setTimeout(() => {
            dispatch(setLoginModal(false));
            setLoginStep(1);
            setEmail('');
            setName('');
            setCanSubmit(true);
            setImage(false);
            setCanSubmitName(true);
        }, 800);
    };

    // 重新發送確認信
    const onSend = () => {
        if (!canRefresh) return;
        sendEmailLogin();
        countDown(59);
    };

    // 網址有帶status 跳註冊成功
    useEffect(() => {
        if (status === 'new') {
            setLoginStep(4);
            dispatch(setLoginModal(true));
        }
    }, [status]);

    useEffect(() => {
        if (user) setName(user?.nickname);
    }, [user]);

    // 重置狀態
    useEffect(() => {
        if (loginStep === 2 && !showLogin) {
            setTimeout(() => {
                setLoginStep(1);
                setEmail('');
            }, 700);
        }
    }, [showLogin]);

    return (
        <>
            {/* 填寫mail */}
            {loginStep === 2 && (
                <div className="login-box">
                    <p className="text-h3 text-[#1e1e1e]">USER LOGIN</p>
                    <p className="text-bd4n text-[#c7c7c7] my-[30px]">{t('USE_EMAIL')}</p>
                    <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={t('EMAIL_PLACEHOLDER')}
                        className={`rounded-[8px] w-full px-[20px] py-[17px] border-1 bg-[transparent] 
            text-bd4n text-[#1e1e1e] placeholder:text-[#9b9b9bbc] ${
                !canSubmit
                    ? 'border-[#ec5760] text-[#ec5760]'
                    : 'border-[#c7c7c7] border-opacity-[.4]'
            }`}
                    />
                    {!canSubmit && (
                        <p className="mt-[10px] text-[#ec5760] text-bd4n text-center">
                            {t('WRONG')}
                        </p>
                    )}
                    <button onClick={sendEmailLogin} className="login-continue">
                        {t('NEXT')}
                    </button>
                </div>
            )}

            {/* 檢查信箱 */}
            {loginStep === 3 && (
                <div className="login-box">
                    <p className="text-h3 text-center text-[#141414]">{t('CHECK')}</p>
                    <div className="text-bd1m text-center text-[#393939] text-opacity-[.8] my-[40px]">
                        {t('VERIFY_LETTER')}
                        <p className="text-bd2b text-[#141414]">{email}</p>
                        {t('RECEIVE')}
                    </div>
                    <div className="text-[#141414] text-center text-bd1b text-opacity-[.8]">
                        {t('NOT_RECEIVE')}
                        <p className="text-bd1m text-[#141414] text-opacity-[.8]">
                            {t('JUNK')}
                            <span
                                onClick={onSend}
                                className={`${
                                    canRefresh ? 'cp text-pale' : 'cursor-not-allowed text-black3'
                                } ml-[2px]`}
                            >
                                {t('SEND_VERIFY')}
                                {!canRefresh && <span className="text-bd3b">{`(${second}s)`}</span>}
                            </span>
                        </p>
                    </div>
                    <button onClick={() => skip('first')} className="login-continue">
                        {t('KNOW')}
                    </button>
                </div>
            )}

            {/* 註冊成功 */}
            {loginStep === 4 && (
                <div className="login-box">
                    <p className="text-h3 text-center text-[#141414]">{t('SUCCESS')}</p>
                    <p className="text-bd1m text-center text-[#393939] text-opacity-[.8] my-[15px]">
                        {t('WELCOME')}
                    </p>
                    <button onClick={() => setLoginStep(5)} className="login-continue">
                        {t('FILL_IN')}
                    </button>
                    <button
                        onClick={skip}
                        className="mt-[20px] text-bd4b w-full mx-auto py-[16px] rounded-[40px] text-[#c7c7c7] text-opacity-[.8] bg-[#f7f7f8]"
                    >
                        {t('SKIP')}
                    </button>
                </div>
            )}

            {/* 選照片 */}
            {loginStep === 5 && (
                <LoginAvatar
                    dispatch={dispatch}
                    setLoginStep={setLoginStep}
                    image={image}
                    setImage={setImage}
                    skip={skip}
                    t={t}
                />
            )}

            {/* 輸入暱稱 */}
            {loginStep === 6 && (
                <div className="login-box">
                    <p className="text-h3 text-center text-[#141414]">{t('APPELLATION')}</p>
                    <p className="text-bd1m text-center text-[#393939] text-opacity-[.8] my-[15px]">
                        {t('NICKNAME')}
                    </p>
                    <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={t('NICKNAME_PLACEHOLDER')}
                        className={`rounded-[8px] text-center w-full px-[20px] py-[17px] border-1 bg-transparent 
              text-bd4n text-[#141414] placeholder:text-[#9b9b9b] ${
                  !canSubmitName
                      ? 'border-[#ec5760] text-[#ffc4c4]'
                      : 'border-[#c7c7c7] border-opacity-[.5]'
              }`}
                    />
                    {!canSubmitName && (
                        <p className="mt-[10px] text-[#ffc4c4] text-bd4n text-center">
                            {t('ACCURATE')}
                        </p>
                    )}
                    <button onClick={handleName} className="login-continue">
                        {t('NEXT_STEP')}
                    </button>
                    <button
                        onClick={skip}
                        className="text-bd4b w-full mt-[20px] mx-auto py-[16px] text-[#c7c7c7] bg-[#f7f7f8] text-opacity-[.8] rounded-[40px]"
                    >
                        {t('SKIP')}
                    </button>
                </div>
            )}

            {/* 歡迎畫面 */}
            {loginStep === 7 && (
                <div className="login-box">
                    <p className="text-h3 text-center text-[#141414]">{`Hi ${name} !`}</p>
                    <p className="text-bd1m text-center text-[#393939] text-opacity-[.8] my-[15px]">
                        {t('JOIN')}
                    </p>
                    <img
                        src={image || user?.image || '/images/layout/default_head.png'}
                        className="w-[140px] h-[140px] object-cover object-center rounded-[50%] border-1 border-[#eaeaea] mx-auto my-[40px]"
                        alt="avatar"
                    />

                    <p className="mb-[20px] text-bd4n text-[#393939] text-opacity-[.8]">
                        {t('EFFORT')}
                    </p>

                    <button onClick={skip} className="login-continue">
                        {t('START')}
                    </button>
                </div>
            )}
        </>
    );
};

export default EmailLogin;
