// 活動小提示
import { useEffect } from 'react';
import Image from 'next/image';

const CampaignHint = ({
    campaignHint,
    showCampaignHint,
    dispatch,
    setCampaignHintModal,
    showCampaignBusy,
    setCampaignBusyModal,
}) => {
    // 處理彈窗顯示
    const handledHint = () => {
        if (showCampaignHint)
            setTimeout(() => {
                dispatch(setCampaignHintModal(false));
            }, 3500);
    };

    const handleBusy = () => {
        if (showCampaignBusy) {
            setTimeout(() => {
                dispatch(setCampaignBusyModal(false));
            }, 3500);
        }
    };

    useEffect(() => {
        handledHint();
    }, [showCampaignHint]);

    useEffect(() => {
        handleBusy();
    }, [showCampaignBusy]);

    return (
        <>
            <div
                className={`campaign-hint text-bd1m text-[#fff]
                ${showCampaignHint ? 'act' : ''}`}
            >
                {/* 獲得輪盤機會 */}
                {campaignHint === 1 && (
                    <>
                        <Image
                            width={28}
                            height={28}
                            src="/images/campaign/icon_finish.svg"
                            alt="icon"
                        />
                        <span>獲得啟動輪盤次數 X1</span>
                    </>
                )}

                {/* 尚未完成任務 */}
                {campaignHint === 2 && (
                    <>
                        <Image
                            width={28}
                            height={28}
                            src="/images/campaign/icon_forbidden.svg"
                            alt="icon"
                        />
                        <span>尚未完成任務</span>
                    </>
                )}
            </div>

            <div
                id="campaign-busy-modal"
                className={`campaign-hint text-bd1m text-[#fff]
                ${showCampaignBusy ? 'act' : ''}`}
            >
                操作過於頻繁，請稍候再嘗試
            </div>
        </>
    );
};

export default CampaignHint;
