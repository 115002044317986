import apiClient from './apiClient';

/** 會員相關 */
class AuthClient {
    // 取得 login user
    getUser = () => apiClient('/auth/user');

    // 登出
    logout = () => apiClient.post('/auth/logout');

    // email 登入
    emailSignin = (email, originalUrl) =>
        apiClient.post('/auth/signin/email/cc', {
            destination: email,
            originalUrl,
        });

    // email驗證
    verifyMail = (mail) => apiClient.post('/auth/members/send-email-verification-code', mail);

    // 取得user wallet
    getUserWallet = (type) => apiClient(type === true ? '/shop/wallet?pure=1' : '/shop/wallet');
}

const authClient = new AuthClient();

export default authClient;
