import apiClient from './apiClient';

class PromoClient {
    // N101(首頁社群廣告)
    // N102_PC(閒置視窗橫幅_PC)
    // N102_MB(閒置視窗橫幅_MB)
    // M101(選單置頂廣告)
    // M102(新聞頁置底廣告)
    getPromoBanner = (positionCode) => apiClient.get(`/promo/banners/${positionCode}`);
}

const promoClient = new PromoClient();

export default promoClient;
