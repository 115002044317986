// 新聞 放大圖片 彈窗

const FullImageModal = ({ setFullImageModal, dispatch, showFullImageModal }) => {
    return (
        <div
            className={`cursor-default full-image fixed top-0 left-0 w-screen trans h-screen center-center
            bg-[#1e1e1e] dark:bg-[#f7f7f8] bg-opacity-[.7]
            ${showFullImageModal ? 'z-[100] opacity-[1]' : 'z-[-1] opacity-0'}`}
            onClick={() => dispatch(setFullImageModal(false))}
        >
            {showFullImageModal && (
                <div
                    className="h-full [&>img]:h-full [&>img]:max-h-full [&>img]:max-w-full [&>img]:object-contain [&>img]:object-center"
                    dangerouslySetInnerHTML={{ __html: showFullImageModal }}
                />
            )}
        </div>
    );
};

export default FullImageModal;
