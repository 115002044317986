// user 選單

import { useSelector } from 'react-redux';
import { userState, userLogout } from '@/slices/userSlice';
import { useRouter } from 'next/navigation';
import Image from 'next/image';
import useComma from '@/hook/useComma';
import UserMenuLink from './UserMenuLink'; // 使用者選單連結
import authClient from '@/common/apiClient/authClient';
import { clearNotification } from '@/slices/notificationSlice';
import { originURL } from '@/slices/originURLSlice';
import useCopy from '@/hook/useCopy';

const UserMenuModal = ({ showUserMenuModal, dispatch, t, setUserMenuModal }) => {
    const copy = useCopy();
    const { cmOrigin } = useSelector(originURL);
    const { user } = useSelector(userState);
    const router = useRouter();
    const numberComma = useComma();

    // 登出
    const onLogout = async () => {
        try {
            await authClient.logout();
            dispatch(setUserMenuModal(false));
            dispatch(userLogout());
            dispatch(clearNotification());
            router.push('/');
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div
            className={`fixed w-screen h-screen top-0 left-0 trans flex justify-end moving-element
            ${showUserMenuModal ? 'z-[9] ulg:z-[100]' : 'z-[-1] opacity-0'}`}
        >
            {/* 關閉用 */}
            <div
                className="absolute w-screen h-screen top-0 left-0 z-[0] moving-element"
                onClick={() => dispatch(setUserMenuModal(false))}
            />

            {/* 使用者選單 */}
            <div
                className={`trans relative z-[1] pb-[40px] max-h-screen noScrollY w-[250px] bg-[#ffffff] dark:bg-[#1E1E1E] border-l-1 border-black2 
          px-[22px] pt-[82px] backdrop-blur-[10px] bg-opacity-[.85] ulg:pt-[27px]
          ${!showUserMenuModal ? 'translate-x-[255px]' : 'translate-x-0'} moving-element`}
            >
                {/* 暱稱 */}
                <p className="text-bd1b text-black">{user?.nickname}</p>

                {/* uid */}
                <p
                    className="cp text-bd3n text-black9 mt-[6px] opacity-[.6]"
                    onClick={() => copy('@' + user?.uid, 'COPY_UID')}
                >
                    @{user?.uid}
                </p>

                <a
                    className="block my-[20px] border-1 border-dashed border-black3  border-l-0 border-r-0 py-[20px]"
                    href={cmOrigin + 'transaction-record'}
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <p className="text-bd3n text-black8 text-left opacity-[.6]">CC Token</p>
                    <div className="between-center">
                        <span className="text-h5 text-black">
                            {numberComma(user?.wallet_info?.cct) || 0}
                        </span>
                        {/* 會員系統 獲得紀錄 */}
                        <p className="flex items-center text-bd3n text-black9 space-x-[10px]">
                            <span className="opacity-[.6]">{t('RECORD')}</span>
                            <Image
                                width={8}
                                height={8}
                                src="/images/icon/icon_right_arrow.svg"
                                alt="more"
                            />
                        </p>
                    </div>
                </a>

                {/* 使用者選單連結 */}
                <UserMenuLink t={t} cmOrigin={cmOrigin} />

                {/* 登出按鈕 */}
                <button
                    onClick={onLogout}
                    className="w-full text-center rounded-[4px] trans py-[14px] text-btn1 text-black border-1 border-black9
          hover:text-white hover:bg-jblack"
                >
                    {t('LOGOUT')}
                </button>
            </div>
        </div>
    );
};

export default UserMenuModal;
