// 社群分享用
import Image from 'next/image';

const SocialShareComponent = ({ copy, page }) => {
    // 回傳 url
    const linkPath = () => {
        if (typeof window !== 'undefined') return window.location.href;
    };

    return (
        <div
            className={`flex ${
                page === 'project'
                    ? 'justify-center items-center w-full [&>*]:w-[30px] [&>*]:h-[30px] dark:!text-[#c7c7c7]'
                    : 'slideTop absolute flex-col gap-y-[10px] right-0 [&>*]:w-[40px] [&>*]:h-[40px] bottom-[70px] z-[1] usm:right-[-10px]'
            } mx-auto [&>*]:after:content-none gap-x-[15px] [&>*]:img-box [&>*]:trans uxs:gap-x-[10px]`}
        >
            {page === 'project' && (
                <>
                    分享
                    <span className="!w-[1px] !h-[20px] border-l-1 border-dashed border-[#b0b0b0] dark:!border-[#949494]" />
                </>
            )}

            <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${linkPath()}`}
                target="_blank"
                rel="noreferrer noopener"
                className="hover:translate-y-[-2px] share-button"
            >
                <Image
                    className="dark:hidden"
                    width={35}
                    height={35}
                    src="/images/column/icon_dark_facebook.svg"
                    loading="lazy"
                    alt="facebook_share"
                />
                <Image
                    className="hidden dark:inline-block"
                    width={35}
                    height={35}
                    src="/images/icon/icon_facebook_light.svg"
                    loading="lazy"
                    alt="facebook_share"
                />
            </a>

            {page === 'project' && (
                <span className="!w-[1px] !h-[20px] border-l-1 border-dashed border-[#b0b0b0] dark:!border-[#949494]" />
            )}

            <a
                href={`https://telegram.me/share/url?url=${linkPath()}`}
                target="_blank"
                rel="noreferrer noopener"
                className="hover:translate-y-[-2px] share-button"
            >
                <Image
                    className="dark:hidden"
                    width={35}
                    height={35}
                    src="/images/column/icon_dark_telegram.svg"
                    loading="lazy"
                    alt="telegram_share"
                />
                <Image
                    className="hidden dark:inline-block"
                    width={35}
                    height={35}
                    src="/images/icon/icon_telegram_light.svg"
                    loading="lazy"
                    alt="telegram_share"
                />
            </a>

            {page === 'project' && (
                <span className="!w-[1px] !h-[20px] border-l-1 border-dashed border-[#b0b0b0] dark:!border-[#949494]" />
            )}

            <a
                href={`https://twitter.com/share?url=${linkPath()}`}
                target="_blank"
                rel="noreferrer noopener"
                className="hover:translate-y-[-2px] share-button"
            >
                <Image
                    className="dark:hidden"
                    width={35}
                    height={35}
                    src="/images/column/icon_dark_twitter.svg"
                    loading="lazy"
                    alt="twitter_share"
                />
                <Image
                    className="hidden dark:inline-block"
                    width={35}
                    src="/images/icon/icon_twitter_light.svg"
                    height={35}
                    loading="lazy"
                    alt="twitter_share"
                />
            </a>

            {page === 'project' && (
                <span className="!w-[1px] !h-[20px] border-l-1 border-dashed border-[#b0b0b0] dark:!border-[#949494]" />
            )}

            <a
                href={`https://social-plugins.line.me/lineit/share?url=${linkPath()}`}
                target="_blank"
                rel="noreferrer noopener"
                className="hover:translate-y-[-2px] share-button"
            >
                <Image
                    className="dark:hidden"
                    width={35}
                    height={35}
                    src="/images/column/icon_dark_line.svg"
                    loading="lazy"
                    alt="line_share"
                />
                <Image
                    className="hidden dark:inline-block"
                    width={35}
                    height={35}
                    src="/images/icon/icon_line_light.svg"
                    loading="lazy"
                    alt="line_share"
                />
            </a>

            {page === 'project' && (
                <span className="!w-[1px] !h-[20px] border-l-1 border-dashed border-[#b0b0b0] dark:!border-[#949494]" />
            )}

            <button
                onClick={() => copy(linkPath())}
                className="hover:translate-y-[-2px] share-button"
            >
                <Image
                    className="dark:hidden"
                    width={35}
                    height={35}
                    src="/images/column/icon_dark_copy.svg"
                    loading="lazy"
                    alt="copy_link"
                />
                <Image
                    className="hidden dark:inline-block"
                    width={35}
                    height={35}
                    src="/images/icon/icon_copy_link.svg"
                    loading="lazy"
                    alt="copy_link"
                />
            </button>
        </div>
    );
};

export default SocialShareComponent;
