'use client';

import { useEffect, useState } from 'react';

const PageLink = ({ text, type }) => {
    const [baseUrl, setBaseUrl] = useState('');

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setBaseUrl(window.location.origin);
        }
    }, []);

    const linkProps = {
        href: `${baseUrl}/crypto-read`,
        target: '_blank',
        rel: 'noreferrer noopener',
        className: 'top-bar ' + (type === 'pc' ? 'hover:text-black7' : 'hover-text'),
    };

    return type === 'pc' ? (
        <a {...linkProps}>{text}</a>
    ) : (
        <a {...linkProps}>
            <span className="w-[6px] h-[6px] bg-black" />
            {text}
        </a>
    );
};

export default PageLink;
